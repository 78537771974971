import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase'


function HomeQuestion17(){
 

    const ptquestion1yesno = ['Yes', 'No'];
    // const ptquestion2yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion3yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion4yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion5yesno = ['First Choice', 'Second Choice', 'Third Choice'];

    const [physicianFirstName, setPhysicialFirstName] = useState("");
    const [physicianLastName, setPhysicianLastName] = useState("");
    const [dob, setDob] = useState("");
    const [homeZip, setHomeZip] = useState("");
    const [firstInitials, setFirstInitials] = useState("");
    const [secondInitials, setSecondInitials] = useState("");
    const [thirdinitials, setThirdInitials] = useState("");
    const [ptquestion1, setPtquestion1] = useState(ptquestion1yesno[0]);
    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()
    const [user, setUser] = useState(null);

    firebase.initializeApp(firebaseConfig);

    const date = new Date();

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let monthNotSpelled = date.getMonth() + 1;
    
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}-${month}-${year}`;
    console.log(currentDate); // "17-6-2022"



    console.log('CURRENT USER',currentUser.uid)

    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)


    const ref = createRef(null);
    const sigRef = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };

    const trim = () => {
         takeScreenshot(ref.current)
    };

    const trim2 = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    const uploadFile = async () => {
        
        console.log("screenshot-->>>>", screenshotImage)
        const imageRef = ref2(imageDb, `signatures/FBDMEagreement/${currentUser.uid}`);
        const fakeURL = screenshotImage.slice(22)
        console.log(fakeURL)
          uploadString(imageRef, fakeURL, 'base64').then(() => {
            window.location.href="/home18"
          })
    
      };
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     
        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            "PhysicianDOB":dob,
            "PhysicianHomeZip":homeZip,
            "AgreedtoTerms?": ptquestion1,
            'fitterID': 'jacksonrossi',
            'signTime': firebase.firestore.FieldValue.serverTimestamp(),
            'status':0

        
            

          }, { merge: true }).then(uploadFile());

          


      };
    
    if (user===null) {
        return <div>Loading</div>
    } else
    return (
        <>
         
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main   className="w-100 ">
                <div  ref={ref} className="w-100 mt-20 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    
                    
                    
                    


                        {/* <div>
                            <input
                                type="text"
                               placeholder="Physician First Name"
                                required
                                value={physicianFirstName} onChange={(e) => { setPhysicialFirstName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Physician Last Name"
                                required
                                value={physicianLastName} onChange={(e) => { setPhysicianLastName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div> */}
                    {/* <p><b>Physician Name:</b>  {user.primaryCarePhysician}</p> */}
                    
<div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`BUSINESS ASSOCIATE AGREEMENT`}</h3>
                        </div>

                    </div>

<p>
This BUSINESS ASSOCIATE AGREEMENT (the “BAA”) is made and entered into as of {month} {day}, {year}, by and between ("Covered Entity") <b>FITTED BRACE LLC</b> , an __<b>X</b>__ LLC ____ Corp organized under the laws of _FLORIDA__ and ("Business Associate") __<b>{user.companyName}</b>__
an __X__ LLC ____ Corp organized inder the laws of __<b>FLORIDA</b>_ in accordance with the meaning given to those terms at 45 CFR §164.501. In this BAA, Covered Entity and Business Associate are each a "Party" and, collectively, are the "Parties"
</p>
<br/>
<br/>

<div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`BACKGROUND`}</h3>
                        </div>

                    </div>

<p>
<b>II</b>. The Parties have entered into or will enter into one or more agreements under which Business Associate provides or will provide certain specified services to Covered Entity (collectively, the “Agreement”);
</p>



<p>
<b>III</b>In providing services pursuant to the Agreement, Business Associate will have access to Protected Health Information;
</p>


<p>
<b>IV</b>By providing the services pursuant to the Agreement, Business Associate will become a “business associate” of the Covered Entity as such term is defined under HIPAA;
</p>


<p>
<b>V</b>. Both Parties are committed to complying with all federal and state laws governing the confidentiality and privacy of health information, including, but not limited to, the Standards for Privacy of Individually Identifiable Health Information found at 45 CFR Part 160 and Part 164, Subparts A and E (collectively, the “Privacy Rule”); and
</p>


<p>
<b>VI</b>. Both Parties intend to protect the privacy and provide for the security of Protected Health Information disclosed to Business Associate pursuant to the terms of this Agreement, HIPAA and other applicable laws.

</p>
<br/>

<div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`AGREEMENT`}</h3>
                        </div>

                    </div>


<p>
<b>NOW, THEREFORE</b>, in consideration of the mutual covenants and conditions contained herein and the continued provision of PHI by Covered Entity to Business Associate under the Agreement in reliance on this BAA, the Parties agree as follows:
</p>


<p>
<b>1. Definitions</b>. For purposes of this BAA, the Parties give the following meaning to each of the terms in this Section 1 below. Any capitalized term used in this BAA, but not otherwise defined, has the meaning given to that term in the Privacy Rule or pertinent law.
</p>


<p>
<b>A. “Affiliate”</b> means a subsidiary or affiliate of Covered Entity that is, or has been, considered a covered entity, as defined by HIPAA.
</p>


<p>
<b>B. “Breach”</b> means the acquisition, access, use, or disclosure of PHI in a manner not permitted under the Privacy Rule which compromises the security or privacy of the PHI, as defined in 45 CFR §164.402.
</p>



<p>
<b>C. “Breach Notification Rule”</b> means the portion of HIPAA set forth in Subpart D of 45 CFR Part 164.
</p>


<p>
<b>D. “Data Aggregation”</b> means, with respect to PHI created or received by Business Associate in its capacity as the “business associate” under HIPAA of Covered Entity, the combining of such PHI by Business Associate with the PHI received by Business Associate in its capacity as a business associate of one or more other “covered entity” under HIPAA, to permit data analyses that relate to the Health Care Operations (defined below) of the respective covered entities. The meaning of “data aggregation” in this BAA shall be consistent with the meaning given to that term in the Privacy Rule.
</p>


<p>
<b>E. “Designated Record Set”</b> has the meaning given to such term under the Privacy Rule, including 45 CFR §164.501.B.
</p>


<p>
<b>F. “De-Identify”</b> means to alter the PHI such that the resulting information meets the requirements described in 45 CFR §§164.514(a) and (b).
</p>


<p>
<b>G. “Electronic PHI”</b> means any PHI maintained in or transmitted by electronic media as defined in 45 CFR §160.103.
</p>


<p>
<b>H. “Health Care Operations”</b> has the meaning given to that term in 45 CFR §164.501.
</p>


<p>
<b>I. “HHS”</b> means the U.S. Department of Health and Human Services.
</p>


<p>
<b>J. “HITECH Act”</b> means the Health Information Technology for Economic and Clinical
Health Act, enacted as part of the American Recovery and Reinvestment Act of 2009, Public Law 111-005.
</p>


<p>
<b>K. “Individual”</b> has the same meaning given to that term i in 45 CFR §§164.501 and 160.130 and includes a person who qualifies as a personal representative in accordance with 45 CFR §164.502(g).

</p>


<p>
<b>L. “Privacy Rule”</b> means that portion of HIPAA set forth in 45 CFR Part 160 and Part 164, Subparts A and E.
</p>


<p>
M. “Protected Health Information” or “PHI” has the meaning given to the term “protected health information” in 45 CFR §§164.501 and 160.103, limited to the information created or received by Business Associate from or on behalf of Covered Entity.
</p>


<p>

<b>N. “Security Incident”</b> means the attempted or successful unauthorized access, use, disclosure, modification, or destruction of information or interference with system operations in an information system.
</p>


<p>
<b>O. “Security Rule”</b> means the Security Standards for the Protection of Electronic Health Information provided in 45 CFR Part 160 & Part 164, Subparts A and C.
</p>


<p>
<b>P. “Unsecured Protected Health Information” or “Unsecured PHI”</b> means any “protected health information” as defined in 45 CFR §§164.501 and 160.103 that is not rendered unusable, unreadable or indecipherable to unauthorized individuals through the use of a technology or methodology specified by the HHS Secretary in the guidance issued pursuant to the HITECH Act and codified at 42 USC §17932(h).
</p>
<br/>
<br/>

<p><b>2. Use and Disclosure of PHI.</b>

</p>


<p>
<b>A.</b> Except as otherwise provided in this BAA, Business Associate may use or disclose PHI as reasonably necessary to provide the services described in the Agreement to Covered Entity, and to undertake other activities of Business Associate permitted or required of Business Associate by this BAA or as required by law.
</p>

<p>
<b>B.</b> Except as otherwise limited by this BAA or federal or state law, Covered Entity authorizes Business Associate to use the PHI in its possession for the proper management and administration of Business Associate’s business and to carry out its legal responsibilities. Business Associate may disclose PHI for its proper management and administration, provided that (i) the disclosures are required by law; or (ii) Business Associate obtains, in writing, prior to making any disclosure to a third party (a) reasonable assurances from this third party that the PHI will be held confidential as provided under this BAA and used or further disclosed only as required by law or for the purpose for which it was disclosed to this third party and (b) an agreement from this third party to notify Business Associate immediately of any breaches of the confidentiality of the PHI, to the extent it has knowledge of the breach.
</p>



<p>
<b>C.</b> Business Associate will not use or disclose PHI in a manner other than as provided in this BAA, as permitted under the Privacy Rule, or as required by law. Business Associate will use or disclose PHI, to the extent practicable, as a limited data set or limited to the minimum necessary amount of PHI to carry out the intended purpose of the use or disclosure, in accordance with Section 13405(b) of the HITECH Act (codified at 42 USC §17935(b)) and any of the act’s implementing regulations adopted by HHS, for each use or disclosure of PHI.
</p>


<p>
<b>D.</b> Upon request, Business Associate will make available to Covered Entity any of Covered Entity’s PHI that Business Associate or any of its agents or subcontractors have in their possession.
</p>


<p>
<b>E.</b> Business Associate may use PHI to report violations of law to appropriate Federal and State authorities, consistent with 45 CFR §164.502(j)(1).
</p>

<br/>
<p>
<b>3. Safeguards Against Misuse of PHI</b> Safeguards Against Misuse of PHI. Business Associate will use appropriate safeguards to prevent the use or disclosure of PHI other than as provided by the Agreement or this BAA and Business Associate agrees to implement administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity and availability of the Electronic PHI that it creates, receives, maintains or transmits on behalf of Covered Entity. Business Associate agrees to take reasonable steps, including providing adequate training to its employees to ensure compliance with this BAA and to ensure that the actions or omissions of its employees or agents do not cause Business Associate to breach the terms of this BAA.
</p>


<p>
<b>4. Reporting Disclosures of PHI and Security Incidents.</b> Business Associate will report to Covered Entity in writing any use or disclosure of PHI not provided for by this BAA of which it becomes aware and Business Associate agrees to report to Covered Entity any Security Incident affecting Electronic PHI of Covered Entity of which it becomes aware. Business Associate agrees to report any such event within five business days of becoming aware of the event.

</p>


<p>
<b>5. Reporting Breaches of Unsecured PHI.</b> Business Associate will notify Covered Entity in writing promptly upon the discovery of any Breach of Unsecured PHI in accordance with the requirements set forth in 45 CFR §164.410, but in no case later than 30 calendar days after discovery of a Breach. Business Associate will reimburse Covered Entity for any costs incurred by it in complying with the requirements of Subpart D of 45 CFR §164 that are imposed on Covered Entity as a result of a Breach committed by Business Associate.
</p>


<p>
<b>6. Mitigation of Disclosures of PHI.</b> Business Associate will take reasonable measures to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of any use or disclosure of PHI by Business Associate or its agents or subcontractors in violation of the requirements of this BAA.
</p>


<p>
<b>7. Agreements with Agents or Subcontractors.</b> Business Associate will ensure that any of its agents or subcontractors that have access to, or to which Business Associate provides, PHI agree in writing to the restrictions and conditions concerning uses and disclosures of PHI contained in this BAA and agree to implement reasonable and appropriate safeguards to protect any Electronic PHI that it creates, receives, maintains or transmits on behalf of Business Associate or, through the Business Associate, Covered Entity. Business Associate shall notify Covered Entity, or upstream Business Associate, of all subcontracts and agreements relating to the Agreement, where the subcontractor or agent receives PHI as described in section 1.M. of this BAA. Such notification shall occur within 30 (thirty) calendar days of the execution of the subcontract by placement of such notice on the Business Associate’s primary website. Business Associate shall ensure that all subcontracts and agreements provide the same level of privacy and security as this BAA.

</p>


<p>

<b>8. Audit Report.</b> Upon request, Business Associate will provide Covered Entity, or upstream Business Associate, with a copy of its most recent independent HIPAA compliance report (AT-C 315), HITRUST certification or other mutually agreed upon independent standards based third party audit report. Covered entity agrees not to re-disclose Business Associate’s audit report.
</p>


<p>
<b>9. Access to PHI by Individuals.</b>
</p>


<p>

<b>A.</b> Upon request, Business Associate agrees to furnish Covered Entity with copies of the PHI maintained by Business Associate in a Designated Record Set in the time and manner designated by Covered Entity to enable Covered Entity to respond to an Individual’s request for
access to PHI under 45 CFR §164.524.
</p>


<p>
<b>B.</b> In the event any Individual or personal representative requests access to the Individual’s PHI directly from Business Associate, Business Associate within ten business days, will forward that request to Covered Entity. Any disclosure of, or decision not to disclose, the PHI requested by an Individual or a personal representative and compliance with the requirements applicable to an Individual’s right to obtain access to PHI shall be the sole responsibility of Covered Entity.
</p>


<p>
<b>10. Amendment of PHI.</b>
</p>


<p>
<b>A.</b> Upon request and instruction from Covered Entity, Business Associate will amend PHI or a record about an Individual in a Designated Record Set that is maintained by, or otherwise within the possession of, Business Associate as directed by Covered Entity in accordance with procedures established by 45 CFR §164.526. Any request by Covered Entity to amend such information will be completed by Business Associate within 15 business days of Covered Entity’s request.
</p>


<p>
<b>B.</b> In the event that any Individual requests that Business Associate amend such Individual’s PHI or record in a Designated Record Set, Business Associate within ten business days will forward this request to Covered Entity. Any amendment of, or decision not to amend, the PHI or record as requested by an Individual and compliance with the requirements applicable to an Individual’s right to request an amendment of PHI will be the sole responsibility of Covered Entity.
</p>


<p>
<b>11. Accounting of Disclosures.</b>
</p>


<p>
<b>A.</b> Business Associate will document any disclosures of PHI made by it to account for such disclosures as required by 45 CFR §164.528(a). Business Associate also will make available information related to such disclosures as would be required for Covered Entity to respond to a request for an accounting of disclosures in accordance with 45 CFR §164.528. At a minimum, Business Associate will furnish Covered Entity the following with respect to any covered disclosures by Business Associate: (i) the date of disclosure of PHI; (ii) the name of the entity or person who received PHI, and, if known, the address of such entity or person; (iii) a brief description of the PHI disclosed; and (iv) a brief statement of the purpose of the disclosure which includes the basis for such disclosure.
</p>


<p>
<b>B.</b> Business Associate will furnish to Covered Entity information collected in accordance with this Section 10, within ten business days after written request by Covered Entity, to permit Covered Entity to make an accounting of disclosures as required by 45 CFR §164.528, or in the event that Covered Entity elects to provide an Individual with a list of its business associates, Business Associate will provide an accounting of its disclosures of PHI upon request of the Individual, if and to the extent that such accounting is required under the HITECH Act or under HHS regulations adopted in connection with the HITECH Act.
</p>


<p>
<b>C.</b> In the event an Individual delivers the initial request for an accounting directly to Business Associate, Business Associate will within ten business days forward such request to Covered Entity.
</p>


<p>
<b>12. Availability of Books and Records.</b> Business Associate will make available its internal practices, books, agreements, records, and policies and procedures relating to the use and disclosure of PHI, upon request, to the Secretary of HHS for purposes of determining Covered Entity’s and Business Associate’s compliance with HIPAA, and this BAA.
</p>


<p>
<b>13. Responsibilities of Covered Entity.</b> With regard to the use and/or disclosure of Protected Health Information by Business Associate, Covered Entity agrees to:
</p>



<p>
<b>A.</b> Notify Business Associate of any limitation(s) in its notice of privacy practices in accordance with 45 CFR §164.520, to the extent that such limitation may affect Business Associate’s use or disclosure of PHI.
</p>



<p>
<b>B.</b> Notify Business Associate of any changes in, or revocation of, permission by an Individual to use or disclose Protected Health Information, to the extent that such changes may affect Business Associate’s use or disclosure of PHI.
</p>



<p>
<b>C.</b> Notify Business Associate of any restriction to the use or disclosure of PHI that Covered Entity has agreed to in accordance with 45 CFR §164.522, to the extent that such restriction may affect Business Associate’s use or disclosure of PHI.
</p>



<p>
<b>D.</b> Except for data aggregation or management and administrative activities of Business Associate, Covered Entity shall not request Business Associate to use or disclose PHI in any manner that would not be permissible under HIPAA if done by Covered Entity.
</p>



<p>
<b>14. Data Ownership.</b> Business Associate’s data stewardship does not confer data ownership rights on Business Associate with respect to any data shared with it under the Agreement, including any and all forms thereof.
</p>



<p>
<b>15. Term and Termination.</b>
</p>


<p>
<b>A.</b> This BAA will become effective on the date first written above, and will continue in effect until all obligations of the Parties have been met under the Agreement and under this BAA.
</p>



<p>
<b>B.</b> Covered Entity may terminate immediately this BAA, the Agreement, and any other related agreements if Covered Entity makes a determination that Business Associate has breached a material term of this BAA and Business Associate has failed to cure that material breach, to Covered Entity’s reasonable satisfaction, within 30 days after written notice from Covered Entity. Covered Entity may report the problem to the Secretary of HHS if termination is not feasible.
</p>



<p>
<b>C.</b> If Business Associate determines that Covered Entity has breached a material term of this BAA, then Business Associate will provide Covered Entity with written notice of the existence of the breach and shall provide Covered Entity with 30 days to cure the breach. Covered Entity’s failure to cure the breach within the 30-day period will be grounds for immediate termination of the Agreement and this BAA by Business Associate. Business Associate may report the breach to HHS.
</p>



<p>
<b>D.</b> Upon termination of the Agreement or this BAA for any reason, all PHI maintained by Business Associate will be returned to Covered Entity or destroyed by Business Associate. Business Associate will not retain any copies of such information. This provision will apply to PHI in the possession of Business Associate’s agents and subcontractors. If return or destruction of the PHI is not feasible, in Business Associate’s reasonable judgment, Business Associate will furnish Covered Entity with notification, in writing, of the conditions that make return or destruction infeasible. Upon mutual agreement of the Parties that return or destruction of the PHI is infeasible, Business Associate will extend the protections of this BAA to such information for as long as Business Associate retains such information and will limit further uses and disclosures to those purposes that make the return or destruction of the information not feasible. The Parties understand that this Section 14.D. will survive any termination of this BAA.
</p>
<br/>
<br/>


<p>
<b>16. Effect of BAA.</b>
</p>



<p>
<b>A.</b> This BAA is a part of and subject to the terms of the Agreement, except that to the extent any terms of this BAA conflict with any term of the Agreement, the terms of this BAA will govern.
</p>



<p>
<b>B.</b> Except as expressly stated in this BAA or as provided by law, this BAA will not create any rights in favor of any third party.
</p>



<p>
<b>17. Regulatory References.</b> A reference in this BAA to a section in HIPAA means the section as in effect or as amended at the time.
</p>



<p>
<b>18. Notices.</b> All notices, requests and demands or other communications to be given under this BAA to a Party will be made via either first class mail, registered or certified or express courier, or electronic mail to the Party’s address given below:
</p>



<p>
<b>A.</b> If to Covered Entity, to: A CUSTOM FIT IN MOTION
</p>
<br/>



<p>
Attn: Mitchell Pantelides
</p>

<p>
T: 727-316-5485
</p>

<p>
E: mitchell@acustomfitinmotion.com
</p>
<br/>
<br/>


<p>
<b>B.</b> If to Business Associate, to:
</p>
<p>
    <b>
{user.companyName}</b>
</p>
<p>
Attn: <b>{user.ownerName}</b>
</p>

<p>
T: <b>{user.companyPhone}</b>
</p>

<p>
E: <b>{user.companyEmail}</b>
</p>
<br/>
<br/>


<p>
<b>19. Amendments and Waiver.</b> This BAA may not be modified, nor will any provision be waived or amended, except in writing duly signed by authorized representatives of the Parties. A waiver with respect to one event shall not be construed as continuing, or as a bar to or waiver of any right or remedy as to subsequent events.
</p>



<p>
<b>20. HITECH Act Compliance.</b> The Parties acknowledge that the HITECH Act includes significant changes to the Privacy Rule and the Security Rule. The privacy subtitle of the HITECH Act sets forth provisions that significantly change the requirements for business associates and the agreements between business associates and covered entities under HIPAA and these changes may be further clarified in forthcoming regulations and guidance. Each Party agrees to comply with the applicable provisions of the HITECH Act and any HHS regulations issued with respect to the HITECH Act. The Parties also agree to negotiate in good faith to modify this BAA as reasonably necessary to comply with the HITECH Act and its regulations as they become effective but, in the event that the Parties are unable to reach agreement on such a modification, either Party will have the right to terminate this BAA upon 30- days’ prior written notice to the other Party.
</p>

<p>
In light of the mutual agreement and understanding described above, the Parties execute this BAA as of
the date first written above.</p>
<br/>
<br/>




























































{/* <div>
<div>
                       
               










                </div>
                            <input
                                type="text"
                               placeholder="Date of Birth (MM/DD/YYYY)"
                                required
                                value={dob} onChange={(e) => { setDob(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Home Zip Code"
                                required
                                value={homeZip} onChange={(e) => { setHomeZip(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div> */}
                        <div class="container">
                    
                    
                    <div>
                        
                        <p>Name: Mitchell Pantelides</p>
                        {/* <p>Name: <b>{user.supplierClientName}</b></p> */}
                        
                        <p><b>Signature Covered Entity</b></p>
                        
                    
                    <div >
                    {!trimmedDataURL &&(
                        <div style={{'width': '500px',
  'height': '200px', 'background-color': '#b6b6b6'}}>
                        <SignaturePad
                        ref={sigPad}
                        canvasProps={{width: 500, height: 200}}

                        />
</div>
                    )}
                        {!trimmedDataURL && (
                        <div>
                        
                        {/* <button  style ={{marginRight:'50px'}}onClick={trim2}>
                            Sign
                            </button>
                            <button 
                                 onClick={clear}>
                            Clear
                            </button> */}
                            
                            
                        </div>
                        )}
                        {trimmedDataURL && (
                            <img  style = {{

                                'background-size': '200px 50px',
                                'width': '200px',
                                'height': '50px',
                                'background-color': 'white'
                            }} src={trimmedDataURL} alt="Signature" />
                        )}


                        </div>
                        {/* <button style={{ marginBottom: '10px' }} onClick={getImage}>
                            Take screenshot
                            </button> */}
                        {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />} */}
                        
                    
                    </div>
                    </div>
                        <div class="container">
                    
                    
                    <div>
                        <p>Printed Name: {user.ownerName}</p>
                        
                        <p><b>Business Assoc Signature</b></p>
                    
                    <div >
                    {!trimmedDataURL &&(
                        <div style={{'width': '500px',
  'height': '200px', 'background-color': '#b6b6b6'}}>
                        <SignaturePad
                        ref={sigPad}
                        canvasProps={{width: 500, height: 200}}

                        />
</div>
                    )}
                        {/* {!trimmedDataURL && (
                        <div>
                        
                        <button  style ={{marginRight:'50px'}}onClick={trim}>
                            Sign
                            </button>
                            <button 
                                 onClick={clear}>
                            Clear
                            </button>
                            
                            
                        </div>
                        )}
                        {trimmedDataURL && (
                            <img  style = {{

                                'background-size': '200px 50px',
                                'width': '200px',
                                'height': '50px',
                                'background-color': 'white'
                            }} src={trimmedDataURL} alt="Signature" />
                        )} */}
                        </div>
                        {/* <button style={{ marginBottom: '10px' }} onClick={getImage}>
                            Take screenshot
                            </button> 
                         {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />} */}
                        
                    
                    </div>
                    </div>
                    <br/>
                        <br/> <br/>
                        <br/> <br/>
                       
                        <br/> 


                   
                   
                    <br/> 


                        <button
                        
                            type="submit"
                            onClick={trim}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Confirm
                            
                        </button> 
                        {screenshotImage ? <button
                        
                            type="submit"
                            onClick={uploadFile}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Continue 
                            
                        </button> :
                        <button
                        disabled
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-100  disabled hover:shadow-xl transition duration-300'
                        > Continue 
                            
                        </button>}

                    {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />}  */}
                </div>
                
            </main>
        </>
    )

}
export default HomeQuestion17;