import React, { createRef, useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import { Document, Page, Annotation, TextField } from 'react-pdf';
import { doc, setDoc } from "firebase/firestore"; 
import 'react-pdf/dist/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import file from './ACFMPDF.pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();


function HomeQuestion15(){
    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }: { numPage} {
//     setNumPages(numPages);
//   }

const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
};


    const { currentUser } = useAuth()

    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );

    const goToPrevPage = () => setPageNumber((prevPage) => prevPage - 1);
	const goToNextPage = () => setPageNumber((prevPage) => prevPage + 1);



    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full  flex self-center place-content-center place-items-center mt-20 ">
            <div>
			{/* <nav>
				<button onClick={goToPrevPage}>Prev</button>
				<button onClick={goToNextPage}>Next</button>
			</nav> */}

			<div style={{ width: 700 }}>
				<Document
					file={file}
					onLoadSuccess={onDocumentLoadSuccess}
                    // inputRef = {this.ref}
				>
                    {/* <TextField /> */}
					<Page pageNumber={pageNumber} width={700} renderTextLayer={true} />
				</Document>
			</div>

			<p>
				Page {pageNumber} of {numPages}
			</p>
		</div>
            </main>

    </>

    )
}
export default HomeQuestion15;