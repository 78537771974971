import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase'
import UploadPaperwork from "./UploadAccredidationCertificate";
import UploadAccredidationCertificate from "./UploadAccredidationCertificate";
import UploadStateLicense from "./UploadStateLicense";
import UploadW9 from "./UploadW9";


// console.log(currentUser.uid)
function HomeQuestion16(){
    const oro2Choices = ['-','Already Credentialed', 'Need Help Being Credentialed'];

    const [dateBusinessStarted, setDateBusinessStarted] = useState("");
    const [supplierClientName, setSupplierClientName] = useState("");
    const [clientPhysicalAddress, setClientPhysicalAddress] = useState("");
    const [clientCity, setClientCity] = useState("");
    const [clientState, setClientState] = useState("");
    const [clientZip, setClientZip] = useState("");
    const [clientMailingAddress, setClientMailingAddress] = useState("");
    const [clientMailingCity, setClientMailingCity] = useState("");
    const [clientMailingState, setClientMailingState] = useState("");
    const [clientMailingZip, setClientMailingZip] = useState("");
    const [clientTelephone, setClientTelephone] = useState("");
    const [clientFax, setClientFax] = useState("");
    const [clientMobile, setClientMobile] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientEIN, setClientEIN] = useState("");
    const [clientNPI, setClientNPI] = useState("");
    const [clientPTAN, setClientPTAN] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [ownerDOB, setOwnerDOB] = useState("");
    const [ownerSS, setOwnerSS] = useState("");
    const [ownerPlaceOfBirth, setOwnerPlaceOfBirth] = useState("");
    const [ownerForeign, setOwnerForeign] = useState("");
    const [ownerRegion, setOwnerRegion] = useState("");
    const [accredetingAgency, setAccredetingAgency] = useState("");
    const [complianceOfficer,setComplianceOfficer ] = useState("");
    const [dateOfAccredidation, setDateOfAccredidation] = useState("");
    const [dateExpires, setDateExpires] = useState("");
    const [dateRenewed, setDateRenewed] = useState("");
    const [oro2, setOro2] = useState(oro2Choices[0]);
    const [mailingPhysicalSame1, setMailingPhysicalSame1] = useState(false);
    const [mailingPhysicalSame2, setMailingPhysicalSame2] = useState(false);
    const [mailingPhysicalSame3, setMailingPhysicalSame3] = useState(false);
    const [mailingPhysicalSame4, setMailingPhysicalSame4] = useState(false);
    const [mailingPhysicalSame5, setMailingPhysicalSame5] = useState(false);

    const [hiredSisterCompany, setHiredSisterCompany] = useState(false);
    const [fitterOnStaff, setFitterOnStaff] = useState(false);
    const [willingToWorkWithSupplierPartner, setWillingToWorkWithSupplierPartner] = useState(false);
    const [willingToWorkWithMarketingPartner, setWillingToWorkWithMarketingPartner] = useState(false);
    const [willingToRedirect, setWillingToRedirect] = useState(false);



    const bodyPart = ['Knee', "Shoulder", "Ankle", "Wrist", "Elbow", "Foot", "Hip"];
    const bodyArea = ["Left", "Right", "Both"];
    const [myValue, setMyValue] = useState(bodyPart[0]);
    const [myValue2, setMyValue2] = useState(bodyPart[0]);
    const nationalmoChoices = ['-','National/Mail Order', 'Local/Statewide'];
    // const DoYouNeedoRO2credentialingOptions = ['-','Yes', 'No'];

    const [nameOfConsultant, setNameOfConsultant] = useState("");
    const handleClick1 = () => setHiredSisterCompany(!hiredSisterCompany)
    const handleClick2 = () => setFitterOnStaff(!fitterOnStaff)
    const handleClick3 = () => setWillingToWorkWithSupplierPartner(!willingToWorkWithSupplierPartner)
    const handleClick4 = () => setWillingToWorkWithMarketingPartner(!willingToWorkWithMarketingPartner)
    const handleClick5 = () => setWillingToRedirect(!willingToRedirect)

   
    





    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()
    const [user, setUser] = useState(null);

    firebase.initializeApp(firebaseConfig);

    const date = new Date();

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let monthNotSpelled = date.getMonth() + 1;
    
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${monthNotSpelled}/${day}/${year}`;
    console.log(currentDate); // "17-6-2022"

    

    console.log('CURRENT USER',currentUser.uid)

    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)

    function DelaythenGo() {
        setTimeout(window.location.href="/home17", 2000)
        

    }
   
    const ref = createRef(null);
    const sigRef = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };


    const trim2 = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    const uploadFile = async () => {
        
        console.log("screenshot-->>>>", screenshotImage)
        const imageRef = ref2(imageDb, `signatures/DMEinitialAgreement/${currentUser.uid}`);
        const fakeURL = screenshotImage.slice(22)
        console.log(fakeURL)
          uploadString(imageRef, fakeURL, 'base64').then(() => {
            window.location.href="/home6"
          })
    
      };
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     
        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            oro2,
            "dmetype":myValue,
            hiredSisterCompany,
            fitterOnStaff,
            willingToWorkWithMarketingPartner,
            willingToWorkWithSupplierPartner,
            willingToRedirect,
            'accessToken': 7


          }, { merge: true }).then(DelaythenGo);

          


      };
    
    // if (user===null) {
    //     return <div>Loading</div>
    // } 
    
    // else

if (user === null) {
    return <div>Loading</div>
} else
    return (
        <>
         
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main   className="w-100 ">
                <div  ref={ref} className="w-100  text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    
                    
                    <div style={{'float':'right',
'width':'50%', color:'blue'}}>


                    {/* <p>A Custom Fit In Motion, LLC 1548 S Missouri Ave. Ste 1009 Clearwater, FL 33756</p> */}


</div>

 <br/>



                    
                    
                    <p> Company Name : <b>{user.companyName} </b>
                        </p>               
                    <p> Company Address: <b>{user.companyAddress}  </b>              
                        </p>               
                                 
                    <p> Company Contact :  <b>{user.ownerName}  </b>          
                        </p>               
                    
                            <p>               
                    Phone :   <b>{user.companyPhone} </b> 
                    </p><p>
                    Email :  <b>{user.companyEmail} </b> 
                    
                            </p>   

                            <div >
                            Type of DME:
                                        <select
                                            onChange={(e) => setMyValue(e.target.value)}
                                            defaultValue={myValue}
                                        >
                                            {nationalmoChoices.map((option, idx) => (
                                            <option key={idx}>{option}</option>
                                            ))}
                                        </select>
                                        
                                        </div>
                            <div >
                            ORO2 Credentialed
                                        <select
                                            onChange={(e) => setOro2(e.target.value)}
                                            defaultValue={oro2}
                                        >
                                            {oro2Choices.map((option, idx) => (
                                            <option key={idx}>{option}</option>
                                            ))}
                                        </select>
                                        
                                        </div>
                            <p><b>Have you hired our sister company – A Custom Fit In Motion?</b>  <input onClick={handleClick1} checked={hiredSisterCompany} type="checkbox" /></p>
                            <p><b>Or do you already have a fitter on staff?</b>  <input onClick={handleClick2} checked={fitterOnStaff} type="checkbox" /></p>
                            <p><b>Willing to work with our Brace Supplier Partner?</b>  <input onClick={handleClick3} checked={willingToWorkWithSupplierPartner} type="checkbox" /></p>
                            <p><b>Willing to work with our Marketing Partner?</b>  <input onClick={handleClick4} checked={willingToWorkWithMarketingPartner} type="checkbox" /></p>

                            <p >If doing your own marketing/advertising, are you:
</p>
<p><b>Willing to redirect your patient inquiries directly to the Fitted Brace site?</b>  <input onClick={handleClick5} checked={willingToRedirect} type="checkbox" /></p>
<p>If no, you will need to upload our custom intake documents to Fitted Brace.
</p>
<p>Click submit below to be taken to our Client Agreement.
</p>

                           
                    
                   
                    <br/> 


                        <button
                        
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Submit
                            
                        </button> 
                       
                            
                    

                    {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />}  */}
                </div>
                
            </main>
        </>
    )
}
export default HomeQuestion16;