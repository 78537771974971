import { useState, useEffect } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import {  db } from "../../firebase/firebase";

import { imageDb } from "../../firebase/firebase";
import { v4 } from "uuid";
import { Button, Grid, Link} from "@mui/material";
import { doc, setDoc } from "firebase/firestore"; 



function UploadAccredidationCertificate({id, userId}) {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const userRef = doc(db, "users" ,userId )

  const imagesListRef = ref(imageDb, "images/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(imageDb, `paperwork/${id}/accredidationcertificate/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
    setDoc(userRef, {
      status:5
    }, { merge: true });

  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    <div >
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      <Button variant="contained" sx={{mt: 1}} color="primary" onClick={uploadFile}>Upload File</Button>
      {/* {imageUrls.map((url) => {
        // return <img src={url}/>;
        return <div>
          <Link href={url}>Fitter Paperwork</Link>
        </div>
      })} */}
    </div>
  );
}

export default UploadAccredidationCertificate;