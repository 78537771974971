import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import emailjs from '@emailjs/browser';

import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase'
import UploadPaperwork from "./UploadAccredidationCertificate";
import UploadAccredidationCertificate from "./UploadAccredidationCertificate";
import UploadStateLicense from "./UploadStateLicense";
import UploadW9 from "./UploadW9";
import { Navigate } from "react-router-dom";


// console.log(currentUser.uid)
function HomeQuestion2(){
    const oro2Choices = ['-','Yes', 'No'];
    const companyTypeOptions = ["-", "LLC", "Corporation"];

    const [dateBusinessStarted, setDateBusinessStarted] = useState("");
    const [supplierClientName, setSupplierClientName] = useState("");
    const [clientPhysicalAddress, setClientPhysicalAddress] = useState("");
    const [clientCity, setClientCity] = useState("");
    const [clientState, setClientState] = useState("");
    const [clientZip, setClientZip] = useState("");
    const [clientMailingAddress, setClientMailingAddress] = useState("");
    const [clientMailingCity, setClientMailingCity] = useState("");
    const [clientMailingState, setClientMailingState] = useState("");
    const [clientMailingZip, setClientMailingZip] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [user, setUser] = useState(null);


    const [clientTelephone, setClientTelephone] = useState("");
    const [clientFax, setClientFax] = useState("");
    const [clientMobile, setClientMobile] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientEIN, setClientEIN] = useState("");
    const [clientNPI, setClientNPI] = useState("");
    const [clientPTAN, setClientPTAN] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [ownerDOB, setOwnerDOB] = useState("");
    const [ownerSS, setOwnerSS] = useState("");
    const [ownerPlaceOfBirth, setOwnerPlaceOfBirth] = useState("");
    const [ownerForeign, setOwnerForeign] = useState("");
    const [ownerRegion, setOwnerRegion] = useState("");
    const [accredetingAgency, setAccredetingAgency] = useState("");
    const [complianceOfficer,setComplianceOfficer ] = useState("");
    const [dateOfAccredidation, setDateOfAccredidation] = useState("");
    const [dateExpires, setDateExpires] = useState("");
    const [dateRenewed, setDateRenewed] = useState("");
    const [oro2, setOro2] = useState(oro2Choices[0]);
    const [mailingPhysicalSame, setMailingPhysicalSame] = useState(false);
    const [hiredSisterCompany, setHiredSisterCompany] = useState(false);
    const [fitterOnStaff, setFitterOnStaff] = useState(false);
    const [willingToWorkWithMarketingPartner, setWillingToWorkWithMarketingPartner] = useState(false);
    const [willingToRedirect, setWillingToRedirect] = useState(false);

    const nationalmoChoices = ['-','National/Mail Order', 'Local/Statewide'];
    // const DoYouNeedoRO2credentialingOptions = ['-','Yes', 'No'];

    const [nameOfConsultant, setNameOfConsultant] = useState("");
    const handleClick1 = () => setMailingPhysicalSame(!mailingPhysicalSame)

   
    





    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()

    firebase.initializeApp(firebaseConfig);

    const date = new Date();

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let monthNotSpelled = date.getMonth() + 1;
    
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${monthNotSpelled}/${day}/${year}`;
    console.log(currentDate); // "17-6-2022"


    // console.log('CURRENT USER',currentUser.uid)

    // useEffect(() => {
    //     firebase
    //       .firestore()
    //       .collection("users")
    //       .doc(currentUser.uid)
    //       .get()
    //       .then((snapshot) => {
    //         if (snapshot.exists) {
    //           setUser(snapshot.data());
    //           console.log(snapshot.data())
    //         } else {
    //           console.log("does not exist");
    //         }
    //       });
    // }, []);

    // console.log(user)

   
    const ref = createRef(null);
    const sigRef = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };

    const trim = () => {
        window.location.href="/home6"
    };

    const trim2 = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
              
            } else {
              console.log("does not exist");
            }
          });
    }, []);
    
    const serviceId = 'service_74nmv5l'
    const templateId = 'template_cup4w39'
    useEffect(() => emailjs.init("3oDmFbVS0kXgKd_q1"), []);

    const sendEmail = () => {

  
      emailjs.send(serviceId, templateId, {
        supplierClientName:companyName ,
        clientPhysicalAddress: companyAddress,
        ownerName: ownerName,
        ownerEmail: companyEmail,
        ownerPhone: companyPhone







        
       });
        
    };

    function functionReef() {
        window.location.href = "/home7"
    }

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    console.log(currentUser.uid)

    const  saveAnswer = async (event) => {
        event.preventDefault();
        sendEmail();

        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            companyAddress,
            companyPhone,
            companyName,
            companyEmail,
            'position': 2,
            'permission': 3,
      "DMEstatus": 0,
      "accessToken": 7,
            'fittedbrace':true,
            companyType,
            ownerName,
            'ACFM Customer?':mailingPhysicalSame

            
            

          }, { merge: true }).then(setTimeout(functionReef, 2000)
            )


          


      };
      if (currentUser) {
        return (
            <>
             
                {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}
    
                <main className="w-full h-screen flex self-center place-content-center place-items-center">
                    <div className= "text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                        
                        
                        
                        <div style={{'float':'right',
    'width':'50%', color:'blue'}}>
    
    
                        {/* <p>A Custom Fit In Motion, LLC 1548 S Missouri Ave. Ste 1009 Clearwater, FL 33756</p> */}
    
    
    </div>
    
     <br/>
    
    
    
                        
                        <p ><b>Fitted Brace offers the most comprehensive and compliant services in the industry <br/>for the national distribution of orthopedic braces, specifically OR02.</b> </p>
                        
                        
                        
                                      
                        {/* <p> Company Name : {user.companyName}             </p>  */}
                        <p> Company Name         <input
                                    type="text"
                                    required
                                    value={companyName} onChange={(e) => { setCompanyName(e.target.value) }}
                                    className="w-150 mt-2 mr-5 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />                  
                            </p> 
                        <p> Company Address         <input
                                    type="text"
                                    required
                                    value={companyAddress} onChange={(e) => { setCompanyAddress(e.target.value) }}
                                    className="w-150 mt-2 mr-5 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />                  
                            </p> 
                            <div >
                                Select Company Type
                                            <select
                                                onChange={(e) => setCompanyType(e.target.value)}
                                                defaultValue={companyType}
                                            >
                                                {companyTypeOptions.map((option, idx) => (
                                                <option key={idx}>{option}</option>
                                                ))}
                                            </select>
                                            
                                            </div>              
                             
                        <p> Company Contact         <input
                                    type="text"
                                    required
                                    value={ownerName} onChange={(e) => { setOwnerName(e.target.value) }}
                                    className="w-150 mt-2 mr-5 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />                  
                            </p>               
                        
                                <p>               
                        Phone         <input
                                    type="text"
                                    required
                                    value={companyPhone} onChange={(e) => { setCompanyPhone(e.target.value) }}
                                    className="w-150 mt-2 px-3 mr-5  py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />      
                        Email         <input
                                    type="text"
                                    required
                                    value={companyEmail} onChange={(e) => { setCompanyEmail(e.target.value) }}
                                    className="w-150 mt-2  mr-5 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />      
                        
                                </p>   
                                {/* <p><b>Are you a client of A Custom Fit in Motion?</b>  <input onClick={handleClick1} checked={mailingPhysicalSame} type="checkbox" /></p> */}
    
                                <p ><b>Learn what Fitted Brace can do for you!</b> </p>
    
    
                               
                        
                       
                        <br/> 
    
    
                            <button
                            
                                type="submit"
                                onClick={saveAnswer}
                                className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                            > Enter
                                
                            </button> 
                           
                                
                        
    
                        {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />}  */}
                    </div>
                    
                </main>
            </>
        )
      }
   

    
}
export default HomeQuestion2;