import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion7(){
    
    
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                    <div class="flex justify-center items-center">
                    <img src={require('../../fittedbrace.png')} height={400 } width={400}   />
                    </div>   
                        <div className="mt-4">
                            <p className="text-gray-800 text-md">{`Thank you for your interest in Fitted Brace. `}</p>
                            <p className="text-gray-800 text-md">{`You will receive a verification email within the next 24 hours to learn more about our services!`}</p>
                        </div>

                    </div>
                    </div>
                    
            </main>
        </>
    )
}
export default HomeQuestion7;