import React from 'react'
import { useAuth } from '../../contexts/authContext'
import FirebaseImageUpload from './FirebaseImageUpload'
import Survey1 from './survey1'
import HomeQuestion2 from './survey2'
import Dmedashboard from './dmedashboard'

const Home = () => {
    const { currentUser } = useAuth()
    return (<>
        {/* <div className='text-2xl font-bold pt-14'>Hello {currentUser.displayName ? currentUser.displayName : currentUser.email}, you are now logged in.</div> */}
        {/* <FirebaseImageUpload/> */}
         <Dmedashboard />
        </>
    )
}

export default Home