import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase'


function HomeQuestion18(){
 

    const ptquestion1yesno = ['Yes', 'No'];
    // const ptquestion2yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion3yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion4yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion5yesno = ['First Choice', 'Second Choice', 'Third Choice'];

    const [physicianFirstName, setPhysicialFirstName] = useState("");
    const [physicianLastName, setPhysicianLastName] = useState("");
    const [dob, setDob] = useState("");
    const [homeZip, setHomeZip] = useState("");
    const [firstInitials, setFirstInitials] = useState("");
    const [secondInitials, setSecondInitials] = useState("");
    const [thirdinitials, setThirdInitials] = useState("");
    const [ptquestion1, setPtquestion1] = useState(ptquestion1yesno[0]);
    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()
    const [user, setUser] = useState(null);
    const [utilizeBraceManufacturer, setWillingUtilizeBraceManufacturer] = useState(false);
    const [utilizeAdvertisingPartner, setUtilizeAdvertisingPartner] = useState(false);
    const handleClick3 = () => setWillingUtilizeBraceManufacturer(!utilizeBraceManufacturer)

    const handleClick4 = () => setUtilizeAdvertisingPartner(!utilizeAdvertisingPartner)

    firebase.initializeApp(firebaseConfig);

    const date = new Date();

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let monthNotSpelled = date.getMonth() + 1;
    
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}-${month}-${year}`;
    console.log(currentDate); // "17-6-2022"



    console.log('CURRENT USER',currentUser.uid)

    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)


    const ref = createRef(null);
    const sigRef = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };

    const trim = () => {
         takeScreenshot(ref.current)
    };

    const trim2 = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    const uploadFile = async () => {
        
        console.log("screenshot-->>>>", screenshotImage)
        const imageRef = ref2(imageDb, `signatures/FITTEDBRACECLIENTagreement/${currentUser.uid}`);
        const fakeURL = screenshotImage.slice(22)
        console.log(fakeURL)
          uploadString(imageRef, fakeURL, 'base64').then(() => {
            window.location.href="/register"
          })
    
      };
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     
        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            
            'fitterID': 'jacksonrossi',
            'signTime': firebase.firestore.FieldValue.serverTimestamp(),
            'status':0,
            'DMEstatus':2,
            utilizeBraceManufacturer,
            utilizeAdvertisingPartner

        
            

          }, { merge: true }).then(uploadFile());

          


      };
    
    if (user===null) {
        return <div>Loading</div>
    } else
     
    return (
        <>
         
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main   className="w-100 ">
                <div  ref={ref} className="w-100 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl mt-5">{`FITTED BRACE - CLIENT AGREEMENT`}</h3>
                        </div>
                        {/* <h1>{currentUser.uid}</h1> */}

                    </div>
                    
                    
                    


                        {/* <div>
                            <input
                                type="text"
                               placeholder="Physician First Name"
                                required
                                value={physicianFirstName} onChange={(e) => { setPhysicialFirstName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Physician Last Name"
                                required
                                value={physicianLastName} onChange={(e) => { setPhysicianLastName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div> */}
                    {/* <p><b>Physician Name:</b>  {user.primaryCarePhysician}</p> */}

                    <p><b>1. The Parties.</b> Agreement made this {month} {day}, {year} by and between Fitted Brace, LLC ("Patient Portal") and __<b>{user.companyName}</b>_ (“Client"). Client agrees to engage the Network for required Orthopedic fitting services. In consideration of the mutual terms, conditions and covenants hereinafter set forth, Network and client agree as follows: <b>The Client hereby contracts with the Network to assess and fit patient's on behalf of the Client and the Network hereby accepts the following terms.</b></p>
                    
       
                    <p><b>2. Services Provided.</b> The Services provided by the Network are described as Per Diem patient assessments and fittings for Prefabricated Custom Fit Orthopedic braces (OR02) and/or Diabetic Shoes (S02) in states where the proper license is on file for the Client and the Network staffs a practitioner.</p>
                    
                    <p>
<b>3. Reimbursement.</b> Client will pay Network directly for each contracted patient assessment/fitting. Network will be responsible to compensate contracted staff for each completed patient assessment/fitting pursuant to this Agreement. The amount will be an agreed upon preset fee schedule based upon the item to be assessed and fit. Payment will be made at the time the final patient delivery paperwork is approved by the Network for submission to the Client. The payment amount will not exceed Fair Market Value and has been explained and delivered via (Fee Schedule). Client is responsible for all fees and equipment expenses in addition to any and all taxes related to their own employees (if any). Payments are not refundable for any services provided.</p>

<p style={{color:'blue'}}>A. We do require an active CC/debit card be kept on file at all times to pay for all fitting fees and for monthly access fees. </p>
<p style={{color:'blue'}}>B. Client agrees that card on file will be charged at the time of each completed fitting and monthly for access fees.</p>

<p>

<b>4. Status.</b> client is a client and may engage in other business activities provided, however, that client shall not during the term of this Agreement solicit Company's contractor's, employees, or accounts on behalf of another entity for any employment, items, products or services.
</p>


<p>
<b>5. Confidentiality.</b> The client agrees that anything that is seen or known during their time under this Agreement shall be kept confidential for a period of five
(5) years. If the client uses information that could be considered 
a trade secret or proprietary information of the Network, 
the Network shall be entitled to monetary and legal compensation. 
Furthermore, during any such breach of confidentiality, the 
Network shall have the right to damages to the fullest extent of the law. 
If any court should rule in favor of the Network in such violation, all attorneys'
 fees of the Network shall be paid by the client. 
  
 <div style={{'background-color': 'yellow', 'width':'230px'}}>

  <input
                                type="text"
                               placeholder="Please Initial Here"
                                required
                                
                                value={firstInitials} onChange={(e) => { setFirstInitials(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
  </div>
 
</p>


<p>
<b>6. Work Product.</b> All ideas, innovations, inventions, improvements, developments, methods, designs, 
analyses, drawings, reports, and all similar or related information which relate to the Network's Business, or any 
business which the Network has taken significant action to pursue, and which are conceived, developed or made by the 
Network during the Employment Period (any of the foregoing, hereinafter “Work Product”), belong to the Network. The client 
will promptly disclose all such Work Product to the Network owner's and perform all actions reasonably requested by the 
Network owner's (whether during or after the Employment Period) to establish and confirm such ownership 
(including, without limitation, assignments, consents, powers of attorney and other instruments). 
<div style={{'background-color': 'yellow', 'width':'230px'}}>

 <input
                                type="text"
                               placeholder="Please Initial Here"
                                required
                                value={thirdinitials} onChange={(e) => { setThirdInitials(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            </div>
</p>


<p>

<b>7. Compliance.</b> OR02 items may not be sent via mail/drop ship to a patient without utilizing an 
O&P Practitioner to fit the product. This is a direct violation of CMS Supplier Standards and is labeled as fraudulent activity. 
OR02 must be fit to a patient by an O&P Practitioner in every State you service, not just your home State. 
CMS and it's Contractors reserve the right to audit files at any time with good cause and if you are found 
to be non compliant in the submission of these claims, they will recoup your payments. If at any time during 

the term of this contract you are found to be billing OR02 items and not utilizing an O&P Practitioner as 
required by Accreditation and CMS standards, Network will file a complaint with the BOC , CMS and other necessary agencies, 
submit to remove the category and fitter from your file and file a FWA statement regarding such activity. 
Your contract will be terminated immediately. 
<div style={{'background-color': 'yellow', 'width':'230px'}}>
 <input
                                type="text"
                               placeholder="Please Initial Here"
                                required
                                value={secondInitials} onChange={(e) => { setSecondInitials(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            </div>
</p>



<p>
<b>8. Personal Guarantee.</b> If the Client is an LLC, Corporation, LLP or other entity, then the individuals signing this agreement hereby agree to jointly and severally as applicable, personally guarantee the payment of all fees and costs payable under this agreement, on behalf of said entity.
</p>


<p>
<b>9. Force Majeure.</b> Network shall not be responsible for any claims or damages resulting from any delays in performance or for non-performance due to unforeseen circumstances or causes beyond· Service Provider's reasonable control.
</p>


<p>
<b>10. Entire Agreement.</b> It is agreed between the parties that there are no other agreements or understandings between them relating to the subject matter of this Agreement. This Agreement supersedes all prior agreements, oral or written, between the parties and is intended as a complete and exclusive statement of the agreement between the parties. No change or modification of this Agreement shall be valid unless the same be in writing and signed by the parties.
</p>


<p>
<b>11. Written Notice.</b> All notices required or permitted to be given hereunder shall be in writing and may be delivered personally or by Certified Mail - Return Receipt Requested, postage prepaid, addressed to the party’s address.
</p>

<p>
<b>12. Governing Law.</b> This Agreement shall be construed in accordance with and governed by the laws of the State of Florida INTENDING TO BE LEGALLY BOUND, the parties hereto have caused this Agreement to be executed as of the date first above written.</p>
<br/>
<br/>

{/*  */}
{/*  */}
{/*  */}
{/*  */}
<p><b>Fitted Brace Fee Schedule:</b></p>
                    
<p><b>Willing to work with our Brace Supplier Partner?</b>  <input onClick={handleClick3} checked={utilizeBraceManufacturer} type="checkbox" /></p> <br></br>
                            <p><b>Willing to work with our Marketing Partner?</b>  <input onClick={handleClick4} checked={utilizeAdvertisingPartner} type="checkbox" /></p><br></br>
                    <p><b>	Fitted Brace Transaction Fee when No to both:	$24/patient processed</b></p>
                    <p><b>Fitted Brace Transaction Fee when Yes to only one: 	$20/patient processed </b></p>
                    <p><b>	Fitted Brace Transaction Fee when Yes to both:	$16/patient processed
 </b></p>
                    

                        <div class="container">
                    
                    
                    <div>
                        
                        <p>ACFIM Signature</p>
                        <p>Name: <b>Mitchell Pantelides</b></p>
                        {/* <p>Name: <b>{user.supplierClientName}</b></p> */}
                        
                        <p><b>Covered Please Sign Below</b></p>
                        
                    
                    <div >
                    {!trimmedDataURL &&(
                        <div style={{'width': '500px',
  'height': '200px', 'background-color': '#b6b6b6'}}>
                        <SignaturePad
                        ref={sigPad}
                        canvasProps={{width: 500, height: 200}}

                        />
</div>
                    )}
                        {!trimmedDataURL && (
                        <div>
                        
                        {/* <button  style ={{marginRight:'50px'}}onClick={trim2}>
                            Sign
                            </button>
                            <button 
                                 onClick={clear}>
                            Clear
                            </button> */}
                            
                            
                        </div>
                        )}
                        {trimmedDataURL && (
                            <img  style = {{

                                'background-size': '200px 50px',
                                'width': '200px',
                                'height': '50px',
                                'background-color': 'white'
                            }} src={trimmedDataURL} alt="Signature" />
                        )}


                        </div>
                        {/* <button style={{ marginBottom: '10px' }} onClick={getImage}>
                            Take screenshot
                            </button> */}
                        {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />} */}
                        
                    
                    </div>
                    </div>
                        <div class="container">
                    
                    
                    <div>
                    <p>Business Signature</p>
                        <p>Name:<b> {user.ownerName}</b></p>
                        
                        <p><b>Business Please Sign Below</b></p>
                    
                    <div >
                    {!trimmedDataURL &&(
                        <div style={{'width': '500px',
  'height': '200px', 'background-color': '#b6b6b6'}}>
                        <SignaturePad
                        ref={sigPad}
                        canvasProps={{width: 500, height: 200}}

                        />
</div>
                    )}
                        {/* {!trimmedDataURL && (
                        <div>
                        
                        <button  style ={{marginRight:'50px'}}onClick={trim}>
                            Sign
                            </button>
                            <button 
                                 onClick={clear}>
                            Clear
                            </button>
                            
                            
                        </div>
                        )}
                        {trimmedDataURL && (
                            <img  style = {{

                                'background-size': '200px 50px',
                                'width': '200px',
                                'height': '50px',
                                'background-color': 'white'
                            }} src={trimmedDataURL} alt="Signature" />
                        )} */}
                        </div>
                        {/* <button style={{ marginBottom: '10px' }} onClick={getImage}>
                            Take screenshot
                            </button> 
                         {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />} */}
                        
                    
                    </div>
                    </div>
                    <br/>
                        <br/> <br/>
                        <br/> <br/>
                       
                        <br/> 


                    
                   
                    <br/> 


                        <button
                        
                            type="submit"
                            onClick={trim}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Confirm
                            
                        </button> 
                        {screenshotImage ? <button
                        
                            type="submit"
                            onClick={uploadFile}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Continue 
                            
                        </button> :
                        <button
                        disabled
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-100  disabled hover:shadow-xl transition duration-300'
                        > Continue 
                            
                        </button>}

                    {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />}  */}
                </div>
                
            </main>
        </>
    )

}
export default HomeQuestion18;