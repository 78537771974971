import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion4(){
    
    const oro2Choices = ['-','Yes', 'No'];
    const accreditationAssistanceChoices = ['-','Yes', 'No'];
    const marketingAssistanceChoices = ['-','Yes', 'No'];
    const nationalmo = ['-','National/Mail Order', 'Local/Statewide'];


    const [location, setLocation] = useState(nationalmo[0]);
    const [or02, setOro2] = useState(oro2Choices[0]);
    const [accreditationAssistance, setAccreditationAssistance] = useState(accreditationAssistanceChoices[0]);
    const [marketingAssistance, setMarketingAssistance] = useState(marketingAssistanceChoices[0]);




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            location,
            or02,
            



            "DMEstatus": 1



          }, { merge: true });
         window.location.href="/home6"

      };
    
   
    return (
        <>
            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Just a Few More Things..`}</h3>
                        </div>

                    </div>






                    
                    
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Are you a national/mail order or Local/Statewide?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setLocation(e.target.value)}
                            defaultValue={location}
                        >
                            {nationalmo.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you need ORO2 credentialing?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setOro2(e.target.value)}
                            defaultValue={or02}
                        >
                            {oro2Choices.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    {/* <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you need Accreditation Assistance?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setAccreditationAssistance(e.target.value)}
                            defaultValue={accreditationAssistance}
                        >
                            {accreditationAssistanceChoices.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div> */}
                    {/* <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you need Marketing Assistance?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setMarketingAssistance(e.target.value)}
                            defaultValue={marketingAssistance}
                        >
                            {marketingAssistanceChoices.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div> */}
                    
                    







                   

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Submit
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion4;