import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, Link, InputLabel, Select, MenuItem, FormControl, DatePicker } from "@mui/material";
// import CustomizedSteppers from "./ProgressBar";
import UploadPaperwork from "./UploadPaperwork";
import Files from "./Files";
import { useState, useEffect } from "react";
import { collection, getCountFromServer, getFirestore, onSnapshot, getDocs, query, where } from '@firebase/firestore';
import {  db } from "../../firebase/firebase";

// import dayjs, { Dayjs } from 'dayjs';
import firebase from "firebase/compat/app";
// import CustomizedSteppersDME from "./ProgressBarDME";
import emailjs from '@emailjs/browser';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore"; 
require("firebase/firestore");
import { firebaseConfig } from '../../firebase/firebase'
import CustomizedSteppersFitter from "./ProgressBarFitter";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius:3,
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};




export default function BasicModal2({ onOpen, onClose, open, row }) {
  const [age, setAge] = useState('');
  const [dataSource, setDataSource] = useState();
  const [user, setUser] = useState(null);
  const [date, setDate] = useState();
  const [fitter, setFitter] = useState([2]);
  const empCollectionRef = collection(db, 'users',);

  firebase.initializeApp(firebaseConfig);

  // console.log("USERRRRR", user.fittingTime)

  // const ft = user.fittingTime

  // console.log("ft", ft)

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(row.id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          setUser(snapshot.data());
        } else {
          console.log("does not exist3");
        }
      });
}, []);

const serviceId = 'service_74nmv5l'
const templateId = 'template_bpz37se'
useEffect(() => emailjs.init("3oDmFbVS0kXgKd_q1"), []);
const sendEmail = () => {

  
  emailjs.send(serviceId, templateId, {
    ownerName: row.ownerName,
    recipient: row.email
   });
    
};

const gotoAgreement = () => {
  window.location.href=`https://storage.cloud.google.com/a-custom-fit-in-motion.appspot.com/signatures/FitterAgreement/${row.id}
  `

}



  const assignNewFittingTime = async() => {
    const userRef = doc(db, "users" , row.id)
    await setDoc(userRef, {
      'fittingTime':(JSON.stringify(date.$d)),
      'status' : 4
    }, { merge: true });
    console.log(date.$d)
    location.reload()
  }
  const approveDME = async() => {
    const userRef = doc(db, "users" , row.id)
    await setDoc(userRef, {
     'status': 2
    }, { merge: true }).then(sendEmail());
    
  }




    React.useEffect(()=> {
        getUsers()
    }, [])


  
  // if (row.fittingTime) {
  //   var fittingsdate = JSON.stringify(new Date((row.fittingTime.seconds)))
  //   console.log(fittingsdate, "TRUETIME")
  //   return fittingsdate

  // }
  
  const getUsers = async() => {
      const data = await getDocs(empCollectionRef);

      // console.log(ydocs)

      
      setDataSource(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }

  console.log("DATASOURCE",dataSource)

  const gotoPDF = () => {
    window.location.href=`https://storage.googleapis.com/a-custom-fit-in-motion.appspot.com/${row.id}-PDF.pdf`

}



  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


        
        <Typography align="center"  sx={{mb: 10}} variant="h3" component="h4">
             Practitioner Profile
           </Typography>



        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
           
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Name
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Email
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.firstName} {row.lastName} {row.company}        
            </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.email} 
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Address
          </Typography>
            </Grid>
            <Grid item xs={6}>
            
            <Typography id="modal-modal-title" sx={{ fontWeight: 'bold'}} variant="h6" component="h2">
          Phone Number
          </Typography>
            </Grid>
            
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.address}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.phoneNumber}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.city}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.livingState}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.zip}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography>
            </Grid>



            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                              Files
                    </Typography>
            </Grid>
            {row.status === 4 || row.status === 3 ? 
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Upload Fitter Paperwork
                    </Typography>
            </Grid> :
            <Grid item xs={6} style={{marginTop: 20}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            </Typography>
    </Grid>}

            <Grid item xs={6}>
                  <Files id={row.email} />
            </Grid>
            {row.status === 4 || row.status === 3 ? 
            <Grid item xs={6} >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            <UploadPaperwork id={row.email} userId={row.id}/>
            </Typography>
            </Grid> :
            <Grid item xs={6} >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            {/* <UploadPaperwork id={row.email} userId={row.id}/> */}
            </Typography>
            </Grid>

            
          
          }
            
            <Grid item xs={6} style={{marginTop: 20}}>
            { row.status === 3 ? 
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Schedule Fitting
                    </Typography> :
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                    </Typography>
}
            </Grid>
            { (row.status === 5 || row.status === 4 )? 
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Scheduled Fitting
                    </Typography>
            </Grid>
            :
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                    </Typography>
            </Grid>
}
            
            <Grid item xs={6} >
            {/* <FormControl sx={{  minWidth: 150 }} size="small">
            <InputLabel >Jackson Rossi</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age......"
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
          {/* <div className="input-field">
                
                <select value={fitter} onChange={(event) => setFitter(event.target.value)}>
                    {dataSource?.map((e,index) => <option  key={index} value={e.id}>{e.firstName} {e.lastname}</option>
                    )}
                </select>
            </div> */}
            { row.status === 3 ? 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
        
        <DateTimePicker
          label="Controlled picker"
          value={date}
          onChange={(newValue) => setDate(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>:
    "" }
          </Grid>
          { (row.status === 5 || row.status === 4 ) ? 
            <Grid item xs={6} >
            <Typography id="modal-modal-title" variant="body1" component="h2">
        
           {row.fittingTime }
            </Typography>
            </Grid>
            :
            <Grid item xs={6} >
            <Typography id="modal-modal-title" variant="body1" component="h2">
        
  
            </Typography>
            </Grid>
            }



            <Grid item xs={6}>
                  
            </Grid>
            <Grid item xs={6}>
                  
            </Grid>
            { row.status === 3 ? 
            <Grid item xs={6} align="center" sx={{mt: 7}}>
            
            <Button variant="contained" color="primary" onClick={assignNewFittingTime}>Confirm Fitting</Button>

            </Grid> :

            <Grid item xs={6} align="center" sx={{mt: 7}}>
                               <Button variant="contained" color="primary" onClick={gotoAgreement}>Download Signed Agreement</Button>
     
            </Grid>



            }
            <Grid item xs={6} sx={{mt: 7}} align="center">
            <Button variant="contained" color="primary" onClick={approveDME}>Approve Practitioner</Button>

            </Grid>
            <Grid item xs={6} sx={{mt: 7}} align="center">

            </Grid>
            {row.DMEstatus=== 1 ?
            
          
            <Grid item xs={6} sx={{mt: 7}} align="center">

            </Grid>
            
          :
          <Grid item xs={6} sx={{mt: 7}} align="center">
                        </Grid>

          
          }
            
            
            
            
            
          
          
   


            
          
          
          
          
          
          
          </Grid>
        {/* <Typography align="center"
  
   variant="h1" component="h1">
              Patient Profile
            </Typography>
            
            <Typography id="transition-modal-description" component="p">
            {row?.firstName}
            </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.firstName} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.lastName}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.email} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.street}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.city} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.livingState}
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.zip}
          </Typography>
          <Button title="CLick ME">BUTTON</Button>
          <button >
     {/* <AddBoxIcon  /> */}


<div>
      
    <CustomizedSteppersFitter style = {{marginTop:10}} status={row.status}/>


    

      </div>
        </Box>
      
        
      </Modal>
    </div>
  );
}