import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
//import Button from '@mui/material/Button';
import Button from 'react-bootstrap/Button';
import MetricCard from 'react-metric-card'
import 'react-metric-card/dist/index.css'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid'
import ImportantTable from './survey11'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useAuth } from '../../contexts/authContext'
import firebase from "firebase/compat/app";
import { firebaseConfig } from '../../firebase/firebase'
import {  db } from "../../firebase/firebase";
import { collection, doc, query, setDoc, where } from "firebase/firestore"; 

import ModeIcon from '@mui/icons-material/Mode';
import NewPatientModal from './NewPatientModal.jsx'
import { getAuth } from 'firebase/auth';
import {auth} from '../../firebase/firebase.js'
import ImportantTable28 from './survey28.jsx';




const Dmedashboard = () => {

  
    const { currentUser } = useAuth()

    const [user, setUser] = useState(null);
    const [userPaymentData, setUserPaymentData] = useState(null);

    const approveDME = async() => {
      window.location.href=`https://acfimpayment.com/`
    }
    // const approveDME = async() => {
    //     const userRef = doc(db, "users" , currentUser.uid)
    //     await setDoc(userRef, {
    //      'DMEstatus': 3
    //     }, { merge: true });
    //     location.reload()
    //   }

    const goToAccount = () => {
      window.location.href=`https://acfimpayment.com/`
  
  }

 
 

    const userId = auth.currentUser?.uid;



    useEffect(() => {
      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            setUser(snapshot.data());
            console.log("USERID", currentUser.uid)
          } else {
            console.log("does not exist");
          }
        });
  }, []);


  useEffect(() => {
      firebase
        .firestore()
        .collection("customers")
        .doc(currentUser.uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            setUserPaymentData(snapshot.data());
            saveAnswer()
          } else {
            console.log("does not exist");
          }
        });
  }, []);

  useEffect(() => {
    if (userPaymentData) {
      saveAnswer()
    }
  }, [])

  const  saveAnswer = async (event) => {

    // const userRef = doc(db, "patients" , currentUser.uid,)
    const userRef = doc(db, "users" , currentUser.uid,)


    await setDoc(userRef, {
        
  "DMEstatus": 2,
  

        
        

      }, { merge: true }
        )


      


  };

    const subscriptionsRef = collection(db, "customers", userId, "checkout_sessions");
    console.log(subscriptionsRef, 'SREF')
    // const q = query(
    //   subscriptionsRef,
    //   where("status", "in", ["trialing", "active"])
    // );
    const q = query(
      subscriptionsRef,
      where("mode", "in", ["subscription", "active"])
    );
  
    console.log('q', q)
    firebase.initializeApp(firebaseConfig);

    console.log(userPaymentData, "USERPAYMENTDATA")

    const OpenModalButton = () => {
      const [open, setOpen] = React.useState(false);
    
      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


      return (
        <>
          <Button variant="primary" onClick={handleOpen} color="primary" >Add Patient</Button>
          <NewPatientModal
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        </>
      );
    };
  const trend = {
    slope: 1,
    description: 'This is the description',
    value: 56
  }

  const trend2 = {
    slope: 1,
    description: 'Increase Since Last Week',
    value: '16.67%'
  }

  const codeSample1 = `<MetricCard
  value={'89.04%'}
  trend={{
    slope: 1,
    description: 'Compared to last week',
    value: '0.5%'
  }}
  title='SERVICE SUCCESS RATE PERCENTAGE'
  fetching={false}
  error={null}
/>`

  const codeSample2 = `<MetricCard
title='SERVICE SUCCESS RATE PERCENTAGE'
fetching={true}
/>`

  const codeSample3 = `<MetricCard
  value={'89.04%'}
  trend={{
    slope: 1,
    description: 'Compared to last week',
    value: '0.5%'
  }}
  title='SERVICE SUCCESS RATE PERCENTAGE'
  fetching={false}
  error={null}
/>`


if (!user) {
  return <div>No User</div>
}

if (userPaymentData === null && currentUser) {
  
  return ( <>
  { user.accessToken === 7 ?
    <div style={{display: 'flex',   position: 'relative', justifyContent:'center', alignItems:'center', height: '100vh'}}>
    
    <Button variant="contained" onClick={approveDME} color="primary" >Pay</Button>
    {/* <Button variant="contained" onClick={approveDME} color="primary" >Pay</Button> */}
    
        
    </div> :
        <div style={{display:'flex', alignItems: 'center', justifyContent:'center', height:'100vh' }}>You do not have access to this portal. Please Logout</div>
      }
      </> )


} 
else if (userPaymentData !== null && currentUser){
  return (<>
  { user.accessToken === 7 ?
    <div className='App'>
    {user.local === 10200 ?
    <img style={{height:200, width:250, display: 'block', margin:'auto', borderRadius:0,marginBottom:-80, marginTop:40
  }}src={require('./Acustomfitinmotion.png')}></img>
    
  :
  <img style={{height:200, width:500, display: 'block', margin:'auto', borderRadius:0,marginBottom:-80, marginTop:40
  }}src={require('./fittedbrace.png')}></img>
  
  
  }
    
    <div style={{ float: 'right', marginTop: -32, marginRight: 20, display: 'flex'
    }}>

<Button variant="primary"  color="primary" onClick={goToAccount} style={{marginRight: '10px'}}>Manage Subscription</Button>

    <OpenModalButton/>

     


    </div>
    
    
   
    <br />
    <br />
    <br />
    
    {/* <Grid container spacing={2} >
      <Grid item lg={3} sm={6} xl={3} xs={12} >
        <MetricCard
          value={'28'}
          trend={{
            slope: 1,
            description: 'Increase since last week',
            value: '19%'
          }}
          title='Total Patients'
          fetching={false}
          error={null}
          icon={<LocalHospitalIcon/>}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          
          // valueFontSize={'20px'}
          // titleFontFamily={'serif'}
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <MetricCard
          value={'4'}
          trend={
            {slope: 1,
            description: 'Increase Since yesterday',
            value: '16.67%'}
          }
          title=' New Patients'
          fetching={false}
          error={null}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          icon={<MedicalServicesIcon/>}
          
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
      <MetricCard
          value={'14'}
          trend={{
            slope: 1,
            description: 'Increase since last period',
            value: '11%'
          }}
          title='Fittings This Period'
          fetching={false}
          error={null}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          icon={<AccessAlarmIcon />}
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
      <MetricCard
          value={'23'}
          trend={{
            slope: 1,
            description: 'Increase since yesterday',
            value: '18%'
          }}
          title='Total Fittings to Date'
          fetching={false}
          error={null}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          icon={<PeopleAltIcon />}
        />
      </Grid>
    </Grid> */}
    {/* <Grid container spacing={2}>
      <Grid item lg={6} sm={6} xl={3} xs={12}>
        <MetricCard
          value={'12%'}
          trend={{
            slope: 1,
            description: 'This is the description',
            value: '56%'
          }}
          title='TIME CONSUMPTION BY SMOKE TEST'
          fetching={false}
          error={null}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          icon={<WiDaySunnyOvercast />}
          // valueFontSize={'20px'}
          // titleFontFamily={'serif'}
        />
      </Grid>
      <Grid item lg={6} sm={6} xl={3} xs={12}>
        <MetricCard
          value={'233 mins'}
          trend={trend2}
          title='TIME CONSUMPTION BY SMOKE TEST'
          fetching={false}
          error={null}
          fetchFunction={() => {}}
          spinnerColor='inherit'
          icon={<AccessAlarmIcon />}
        />
      </Grid>
    </Grid> */}

    <div style={{display:'flex', flexDirection:'row', display: 'flex',}} >
    
    <ImportantTable />
    <ImportantTable28 />   

  


</div>
{/* <FitterMap />  */}
  </div>
    :
    <div style={{display:'flex', alignItems: 'center', justifyContent:'center', height:'100vh' }}>You do not have access to this portal. Please Logout</div>}
    </>)

}

}


export default Dmedashboard