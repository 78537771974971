import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase'
import UploadPaperwork from "./UploadAccredidationCertificate";
import UploadAccredidationCertificate from "./UploadAccredidationCertificate";
import UploadStateLicense from "./UploadStateLicense";
import UploadW9 from "./UploadW9";


// console.log(currentUser.uid)
function HomeQuestion6(){
    const oro2Choices = ['-','Yes', 'No'];

    const [dateBusinessStarted, setDateBusinessStarted] = useState("");
    const [supplierClientName, setSupplierClientName] = useState("");
    const [clientPhysicalAddress, setClientPhysicalAddress] = useState("");
    const [clientCity, setClientCity] = useState("");
    const [clientState, setClientState] = useState("");
    const [clientZip, setClientZip] = useState("");
    const [clientMailingAddress, setClientMailingAddress] = useState("");
    const [clientMailingCity, setClientMailingCity] = useState("");
    const [clientMailingState, setClientMailingState] = useState("");
    const [clientMailingZip, setClientMailingZip] = useState("");
    const [clientTelephone, setClientTelephone] = useState("");
    const [clientFax, setClientFax] = useState("");
    const [clientMobile, setClientMobile] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientEIN, setClientEIN] = useState("");
    const [clientNPI, setClientNPI] = useState("");
    const [clientPTAN, setClientPTAN] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [ownerDOB, setOwnerDOB] = useState("");
    const [ownerSS, setOwnerSS] = useState("");
    const [ownerPlaceOfBirth, setOwnerPlaceOfBirth] = useState("");
    const [ownerForeign, setOwnerForeign] = useState("");
    const [ownerRegion, setOwnerRegion] = useState("");
    const [accredetingAgency, setAccredetingAgency] = useState("");
    const [complianceOfficer,setComplianceOfficer ] = useState("");
    const [dateOfAccredidation, setDateOfAccredidation] = useState("");
    const [dateExpires, setDateExpires] = useState("");
    const [dateRenewed, setDateRenewed] = useState("");
    const [oro2, setOro2] = useState(oro2Choices[0]);
    const [mailingPhysicalSame, setMailingPhysicalSame] = useState(false);

    const nationalmoChoices = ['-','National/Mail Order', 'Local/Statewide'];
    // const DoYouNeedoRO2credentialingOptions = ['-','Yes', 'No'];

    const [nameOfConsultant, setNameOfConsultant] = useState("");
    const handleClick1 = () => setMailingPhysicalSame(!mailingPhysicalSame)

   
    





    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()
    const [user, setUser] = useState(null);

    firebase.initializeApp(firebaseConfig);

    const date = new Date();

    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let monthNotSpelled = date.getMonth() + 1;
    
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${monthNotSpelled}/${day}/${year}`;
    console.log(currentDate); // "17-6-2022"

    const data = [
        { day: <b>Sunday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Monday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Tuesday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Wednesday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Thursday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Friday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },
        { day: <b>Saturday</b>, 
        open1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close1: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        open2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        close2: <select >
            <option value="Closed">Closed</option>
            <option value="12am">12am</option>
            <option value="12am">1am</option>
            <option value="12am">2am</option>
            <option value="12am">3am</option>
            <option value="12am">4am</option>
            <option value="12am">5am</option>
            <option value="12am">6am</option>
            <option value="12am">7am</option>
            <option value="12am">8am</option>
            <option value="12am">9am</option>
            <option value="12am">10am</option>
            <option value="12am">11am</option>
            <option value="12am">12pm</option>
            <option value="12am">1pm</option>
            <option value="12am">2pm</option>
            <option value="12am">3pm</option>
            <option value="12am">4pm</option>
            <option value="12am">5pm</option>
            <option value="12am">6pm</option>
            <option value="12am">7pm</option>
            <option value="12am">8pm</option>
            <option value="12am">9pm</option>
            <option value="12am">10pm</option>
            <option value="12am">11pm</option>
            </select>, 
        },






      
    ]

    console.log('CURRENT USER',currentUser.uid)

    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)

   
    const ref = createRef(null);
    const sigRef = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };

    const trim = () => {
        window.location.href="/home16"
    };

    const trim2 = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    const uploadFile = async () => {
        
        console.log("screenshot-->>>>", screenshotImage)
        const imageRef = ref2(imageDb, `signatures/DMEinitialAgreement/${currentUser.uid}`);
        const fakeURL = screenshotImage.slice(22)
        console.log(fakeURL)
          uploadString(imageRef, fakeURL, 'base64').then(() => {
            window.location.href="/home6"
          })
    
      };
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     
        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            'fitterID': 'jacksonrossi',
            'signTime': firebase.firestore.FieldValue.serverTimestamp(),
            'status':0,
            dateBusinessStarted,
            supplierClientName,
            clientPhysicalAddress
            ,clientState
            ,clientCity
            ,clientZip
            ,clientMailingAddress
            ,clientMailingCity
            ,clientMailingState
            ,clientMailingZip
            ,clientTelephone
            ,clientMobile
            ,clientFax
            ,clientEmail
            ,clientNPI
            ,clientEIN
            ,clientPTAN,
            oro2
            ,ownerName
            ,ownerDOB
            ,ownerSS
            ,ownerPlaceOfBirth
            ,ownerRegion
            ,accredetingAgency
            ,complianceOfficer
            ,dateOfAccredidation
            ,dateExpires
            ,dateRenewed
            ,nameOfConsultant





        
            

          }, { merge: true }).then(uploadFile());

          


      };
    
    // if (user===null) {
    //     return <div>Loading</div>
    // } 
    
    // else

if (currentUser === null) {
    return <div>Loading</div>
} else
    return (
        <>
         
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main   className="w-100 ">
                <div  ref={ref} className="w-100  text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    
                    
                    <div style={{'float':'right',
'width':'50%', color:'blue'}}>


                    {/* <p>A Custom Fit In Motion, LLC 1548 S Missouri Ave. Ste 1009 Clearwater, FL 33756</p> */}


</div>

 <br/>



                    
                    <p ><b>Fitted Brace Offers Patient Processing from A-Z</b> </p>
                    <p >Fitted brace has automated a complete Patient Intake, collecting not only the current pain issue, but a full medical history and insurance information.</p>
                    <p >Once completed the insurance information is sent to your company for verification.</p>
                    <p >Once you have verified insurance, we begin chasing the patient’s doctor for an Rx.</p>
                    <p >Once the Rx is collected, a local fitter is assigned, you are notified and instructed to ship the brace to the assigned fitter.</p>
                    <p >Once the fitter receives the brace, they set an appointment with the patient for a fitting.</p>
                    <p >Once the fitting is complete, the fitter notifies your company so you can bill insurance.</p>
                    <p><b>What do these services Cost?</b></p>
                    <p>Our fees are strictly transactional – a flat rate per consumer/patient processed.</p>
                    <p>We do offer incentives to reduce the flat rate when you work with one or more of our partners.</p>
                    <p><b>Orthopedic Brace Manufacturer/Distributor Partner
</b></p>
                    <p>Fitted Brace has partnered with a trusted manufacturer/distributor of quality products, offering your company multiple brands and the best brace for each body part – back, shoulder, elbow, wrist, hip, knee and ankle.</p>
                    <p><b>Compliant Marketing Partner</b></p>
                    <p>Fitted Brace has partnered with a marketing company that has won awards advertising on Facebook…which is the #1 platform for reaching seniors 65 and older.</p>
                    <p>If you agree to work with <b>one of our partners</b> – you <b>save 16.5%</b> on your transaction fees.
</p>
                    <p>Should you choose to work with <b>both our partners</b> – you <b>save 33%</b> on your transaction fees.
</p>
                    <p>You decide: $24/transaction; $20/transaction; or $16/transaction.
</p>
                    <p><b>Ready to engage our services?</b>
</p>
                    
                   
                    <br/> 


                        <button
                        
                            type="submit"
                            onClick={trim}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Let's Get Started
                            
                        </button> 
                       
                            
                    

                    {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />}  */}
                </div>
                
            </main>
        </>
    )
}
export default HomeQuestion6;