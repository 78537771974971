import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 
import { Navigate, Link } from 'react-router-dom'
import { getAuth, signInAnonymously } from "firebase/auth";

const  createAnonUser = async (event) => {
    event.preventDefault();
    // const userRef = doc(db, "users" , currentUser.uid,)

    // addressRef = doc(db, "users" , currentUser.uid)


    const auth = getAuth();
    signInAnonymously(auth)
    .then(() => {
         {
            console.log(auth)
            
        }
        // Signed in..
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
    });
    


  };

function HomeQuestion19(){
    const [companyName, setCompanyName] = useState("");

    const { userLoggedIn } = useAuth()

    return (
        <>
            {userLoggedIn && (<Navigate to={'/home2'} replace={true} />)}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                    <div class="flex justify-center items-center">
                    <img src={require('../../fittedbrace.png')} height={400 } width={400}   />
                    </div>   
                        <div className="mt-4">
                            <p className="text-gray-800 text-md">{`Thank you for your interest in Fitted Brace. `}</p>
                            {/* <p className="text-gray-800 text-md">{`You will receive a verification email within the next 24 hours to learn more about our services!`}</p> */}
                            <p >To learn more, please proceed using the link below</p>
                        </div>
                        {/* <p> Company Name         <input
                                    type="text"
                                    required
                                    value={companyName} onChange={(e) => { setCompanyName(e.target.value) }}
                                    className="w-150 mt-2 mr-5 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />                  
                            </p>  */}
                            <p className="text-center text-lg"><Link to={'/home2'}  onClick={createAnonUser} className="hover:underline font-bold">Let's Go!</Link></p>


                    </div>
                    </div>
                    
            </main>
        </>
    )
}
export default HomeQuestion19;