import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  Grid, Link, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import ProgressBar from "./ProgressBar";
import CustomizedSteppers from "./ProgressBar";
import UploadPaperwork from "./UploadAccredidationCertificate";
import Files from "./Files";
import { useState } from "react";
import { collection, getCountFromServer, getFirestore, onSnapshot, getDocs, query, where } from '@firebase/firestore';
import {  db } from "../../firebase/firebase";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore"; 
import axios from 'axios';
import Button from 'react-bootstrap/Button';







const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius:3,
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};




export default function BasicModal({ onOpen, onClose, open, row }) {
  const [dataSource, setDataSource] = useState();
  const [fitter, setFitter] = useState();
  const empCollectionRef = collection(db, 'users',);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [epochName, setEpochName] = useState(null);
  const [physicianFirstName, setPhysicialFirstName] = useState(null);
  const [physicianLastName, setPhysicialLastName] = useState(null);
  const [faxable, setFaxable] = useState(null);


  console.log("SELECTED USER",selectedUser)
  // console.log("SELECTED EPOCH",selectedUser.created_epoch)


  const assignNewFitter = async() => {
    const userRef = doc(db, "users" , row.id)
    await setDoc(userRef, {
      'assignedFitter': fitter
    }, { merge: true });
  }

  const approveInsurance = async() => {
    const userRef = doc(db, "users" , row.id)
    await setDoc(userRef, {
      'status': 1
    }, { merge: true });
    location.reload();
  }

    React.useEffect(()=> {
        getUsers()
    }, [])


    const url = `https://npiregistry.cms.hhs.gov/api/?number=&enumeration_type=&taxonomy_description=&name_purpose=&first_name=${physicianFirstName}&use_first_name_alias=&last_name=${physicianLastName}&organization_name=&address_purpose=&city=&state=&postal_code=&country_code=&limit=&skip=&pretty=&version=2.1`

    const doctorLookup = async () => {
      axios.get((url), {
    })
    .then((res) => {
    setUserData(res.data.results);
    setSelectedUser(res.data.results[0])
    console.log('RESULTS',res.data.results[0])
    }
    
    )
    // .then((res) => console.log('DTAAA',res.data.resource.start_time))
    };
  
  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId)
    setEpochName(selectedUserId)
    setFaxable(selectedUserId)
  }

    
  
  
  const getUsers = async() => {
      const data = await getDocs(empCollectionRef);

      // console.log(ydocs)

      
      setDataSource(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }


  const gotoPDF = () => {
    window.location.href=`https://storage.googleapis.com/a-custom-fit-in-motion.appspot.com/${row.id}-PDF.pdf`

}



  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {/* <Typography align="center"  sx={{mb: 10}}
  
  variant="h3" component="h4">
             Patient Profile
           </Typography> */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
           
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Name
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Email
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.firstName} {row.lastName}          
            </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.email} 
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Address
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" sx={{ fontWeight: 'bold'}} variant="h6" component="h2">
          
          </Typography>
            </Grid>
            
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.street}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.permission}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.city}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.livingState}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.zip}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography>
            </Grid>



            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                              Files
                    </Typography>
            </Grid>
            { (row.status === 0 || row.status === 1 )? 
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Upload RX
                    </Typography>
            </Grid> :
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                    </Typography>
            </Grid>
}           
            <Grid item xs={6}>
                  <Files id={row.email} />
            </Grid>
            { (row.status === 0 || row.status === 1 )? 

            <Grid item xs={6} >
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                    <UploadPaperwork id={row.email} userId={row.id}/>
                    </Typography>
            </Grid>:
            <Grid item xs={6} >
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                    </Typography>
            </Grid>
}
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Select Fitter
                    </Typography>
            </Grid>
            <Grid item xs={6} style={{marginTop: 20}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      Scheduled Fitting
                    </Typography>
            </Grid>
            
            
            <Grid item xs={6} >
          <div className="input-field">
                
                <select value={fitter} onChange={(event) => setFitter(event.target.value)}>
                    {dataSource?.map((e,index) => <option key={index} value={e.id}>{e.firstName} {e.lastName}</option>
                    )}
                </select>
            </div>
          </Grid>
            
            <Grid item xs={6} >
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.fittingTime}         
            </Typography>
            </Grid>
            
            <Grid item xs={6}>
                  
            </Grid>
            <Grid item xs={6}>
                  
            </Grid>
            <Grid item xs={6} align="center" >
            <Button variant="primary" color="primary" onClick={assignNewFitter}>Assign New Fitter</Button>

            </Grid>
            <Grid item xs={6}  align="center">

              <Button 
              variant="primary" 
              color="primary" 
              onClick={gotoPDF}>
                Create PDF
              </Button>
            
            </Grid>
            {/* <Grid item xs={6} sx={{mt: 7}} align="center">
            <div>
                            <input
                                type="text"
                               placeholder="Doctor First Name"
                                required
                                value={physicianFirstName} onChange={(e) => { setPhysicialFirstName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <input
                                type="text"
                               placeholder="Doctor Last Name"
                                required
                                value={physicianLastName} onChange={(e) => { setPhysicialLastName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

            </Grid> */}
            {/* <Grid item xs={6} sx={{mt: 7}} align="center">
              Doctor CLI: {epochName}
             

            </Grid>
            <Grid item xs={6} sx={{mt: 7}} align="center">
             

            </Grid>
            {
              faxable ? 
              <Grid item xs={6} sx={{mt: 7}} align="center">
            <Button variant="primary" color="primary" onClick={console.log("FAX SENT")}>Send Fax</Button>

             

            </Grid>
            :
            <Grid item xs={6} sx={{mt: 7}} align="center">

             

            </Grid> 
            }*/}
            
            
            

            </Grid>
            
            <Grid item xs={6} sx={{mt: 7}} align="center">
            
            {row.status === 0 && 
            <Grid item xs={6} align="center" sx={{mb: -10}}>
            <Button variant="primary" color="primary" onClick={approveInsurance} >Approve Insurance</Button>

            </Grid>
            
            
            }
            
          </Grid>
          {userData &&
            <>
            
            
              
              <select onChange={handleUserChange}>
                {userData.map(user => (
                  <option key = {user.created_epoch} value={user.created_epoch} >{user.basic.first_name} {user.basic.last_name} - {user.addresses[0].city}</option>
                ))}
              </select>
           
            </>
            }
            
          
      


<div>
      <CustomizedSteppers style = {{marginTop:8}} status={row.status}/>

      </div>
        </Box>
      
        
      </Modal>
    </div>
  );
}