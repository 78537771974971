import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion14(){

    const diagnosedDiabeticYesNo = ['-','Yes', 'No'];
    
    const tarsalTunnelYesNo = ['-','Yes', 'No']
    const howLongHaveYouHadThisPainQuestions = ['-','A Week', 'A Month', "Longer"]
    const isThePainAnswers = ['-',"Present all the time", "Just occasionally", 'Activity Related']
    const isThePainAnswers2 = ['-',"Burning", "Stabbing", 'Radiating (travels to other body areas)']
    const forThisPartOfTheBodyAnswers = ['-',"XRay", "Injections", "MRI", "None"]
    const medicationAnswers = ['-',"None","Anti-inflammatory", 'Injections']
    



   
    const [complaintDuetoAnInjury, setComplaintDuetoAnInjury] = useState(diagnosedDiabeticYesNo[0]);
    const [previousSurgeries, setPreviousSurgeries] = useState(diagnosedDiabeticYesNo[0]);
    const [howLongHaveYouHadThisPain, setHowLongHaveYouHadThisPain] = useState(howLongHaveYouHadThisPainQuestions[0]);
    const [isThePain, setIsThePain] = useState(isThePainAnswers[0]);
    const [isThePain2, setIsThePain2] = useState(isThePainAnswers2[0]);
    const [forThisPartOfTheBody, setForThisPartOfTheBody] = useState(forThisPartOfTheBodyAnswers[0]);
    const [medication, setMedication] = useState(medicationAnswers[0]);
    const [treatment1, setTreatment1] = useState("")
  
    
    
    
    
    
    const [dateOfInjury, setDateOfInjury] = useState('');
    const [dateOfSurgery, setDateOfSurgery] = useState('');




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            complaintDuetoAnInjury,
            previousSurgeries,
            howLongHaveYouHadThisPain,
            dateOfInjury,
            dateOfSurgery,
            isThePain,
            isThePain2,
            forThisPartOfTheBody,
            medication,
            treatment1
          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/home6"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );

     


    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center mt-40 ">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Present Complaint`}</h3>
                        </div>

                    </div>






                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Complaint Due to an injury?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setComplaintDuetoAnInjury(e.target.value)}
                            defaultValue={complaintDuetoAnInjury}
                        >
                            {tarsalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        </div>
                        
                    </div>
                    </div>
                    <div>
                            <label className="text-sm text-gray-600 font-bold">
                            If Yes, Date of injury? 
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={dateOfInjury} onChange={(e) => { setDateOfInjury(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>


                    
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Previous Surgeries for this issue?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPreviousSurgeries(e.target.value)}
                            defaultValue={previousSurgeries}
                        >
                            {tarsalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        </div>
                        
                    </div>
                    </div>
                    <div>
                            <label className="text-sm text-gray-600 font-bold">
                            If Yes, Date of Surgery 
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={dateOfSurgery} onChange={(e) => { setDateOfSurgery(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>


                    
                    <div class="row  justify-content-right">
                    
                    <div class="col-4">
                        <h2><b>How long have you had this pain?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setHowLongHaveYouHadThisPain(e.target.value)}
                            defaultValue={howLongHaveYouHadThisPain}
                        >
                            {howLongHaveYouHadThisPainQuestions.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    

                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Is the Pain..</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setIsThePain(e.target.value)}
                            defaultValue={isThePain}
                        >
                            {isThePainAnswers.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Is the Pain..</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setIsThePain2(e.target.value)}
                            defaultValue={isThePain2}
                        >
                            {isThePainAnswers2.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>For this body part, have you had:</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setForThisPartOfTheBody(e.target.value)}
                            defaultValue={forThisPartOfTheBody}
                        >
                            {forThisPartOfTheBodyAnswers.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Does medication help with the pain:</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setMedication(e.target.value)}
                            defaultValue={medication}
                        >
                            {medicationAnswers.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>List all Treatments Previously Tried</b></h2>
                        <h3>(i.e., prescribed meds, physical therapy, electro-therapy, </h3>
                        <h3>   heat/ice, ointments):</h3>
                    </div>
                    <div>
                            
                            <input
                                type="text"
                               
                                
                                value={treatment1} onChange={(e) => { setTreatment1(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                    </div>
                    </div>
                    
                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion14;