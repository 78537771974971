import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion10(){

    const diagnosedDiabeticYesNo = ['-','Yes', 'No'];
    const neuropathyYesNo = ['-','Yes', 'No'];
    const arthritisYesNo = ['-','Yes', 'No'];
    const carpalTunnelYesNo = ['-','Yes', 'No'];
    const tennisElbowYesNo = ['-','Yes', 'No'];
    const frozenShoulderYesNo = ['-','Yes', 'No'];
    const tendinitisYesNo = ['-','Yes', 'No'];
    const sciaticaYesNo = ['-','Yes', 'No'];
    const radiculopathyYesNo = ['-','Yes', 'No'];
    const goutYesNo = ['-','Yes', 'No'];
    const plantarFasciitisYesNo = ['-','Yes', 'No'];
    const tarsalTunnelYesNo = ['-','Yes', 'No'];
    



    const [diagnosedDiabetic, setDiagnosedDiabetic] = useState(diagnosedDiabeticYesNo[0]);
    const [neuropathy, setNeuropathy] = useState(neuropathyYesNo[0]);
    const [arthritis, setArthritis] = useState(arthritisYesNo[0]);
    const [carpalTunnel, setCarpalTunnel] = useState(carpalTunnelYesNo[0]);
    const [tennisElbow, setTennisElbow] = useState(tennisElbowYesNo[0]);
    const [frozenShoulder, setFrozenShoulder] = useState(frozenShoulderYesNo[0]);
    const [tendinitis, setTendinitis] = useState(tendinitisYesNo[0]);
    const [sciatica, setSciatica] = useState(sciaticaYesNo[0]);
    const [radiculopathy, setRadiculopathy] = useState(radiculopathyYesNo[0]);
    const [gout, setGout] = useState(goutYesNo[0]);
    const [plantarFasciitis, setPlantarFasciitis] = useState(plantarFasciitisYesNo[0]);
    const [tarsalTunnel, setTarsalTunnel] = useState(tarsalTunnelYesNo[0]);
    const [conditionsNotListed, setConditionsNotListed] = useState('');




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            diagnosedDiabetic,
            neuropathy,
            arthritis,
            carpalTunnel,
            tennisElbow,
            frozenShoulder,
            tendinitis,
            sciatica,
            radiculopathy,
            gout,
            plantarFasciitis,
            tarsalTunnel,
            conditionsNotListed


          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/home13"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center mt-40 ">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Have you been diagnosed with any of the following?`}</h3>
                        </div>

                    </div>






                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Diabetes</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setDiagnosedDiabetic(e.target.value)}
                            defaultValue={diagnosedDiabetic}
                        >
                            {diagnosedDiabeticYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>


                    
                    <div class="row  justify-content-right">
                    
                    <div class="col-4">
                        <h2><b>Neuropathy</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setNeuropathy(e.target.value)}
                            defaultValue={neuropathy}
                        >
                            {neuropathyYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    

                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Arthritis</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setArthritis(e.target.value)}
                            defaultValue={arthritis}
                        >
                            {arthritisYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Carpal Tunnel</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setCarpalTunnel(e.target.value)}
                            defaultValue={carpalTunnel}
                        >
                            {carpalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Tennis Elbow</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setTennisElbow(e.target.value)}
                            defaultValue={tennisElbow}
                        >
                            {tennisElbowYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Frozen shoulder</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setFrozenShoulder(e.target.value)}
                            defaultValue={frozenShoulder}
                        >
                            {frozenShoulderYesNo.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Tendinitis</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setTendinitis(e.target.value)}
                            defaultValue={tendinitis}
                        >
                            {tendinitisYesNo.map((yesno2, idx) => (
                            <option key={idx}>{yesno2}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Sciatica</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setSciatica(e.target.value)}
                            defaultValue={sciatica}
                        >
                            {sciaticaYesNo.map((yesno3, idx) => (
                            <option key={idx}>{yesno3}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Radiculopathy</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setRadiculopathy(e.target.value)}
                            defaultValue={radiculopathy}
                        >
                            {radiculopathyYesNo.map((yesno4, idx) => (
                            <option key={idx}>{yesno4}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Tarsal tunnel</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setTarsalTunnel(e.target.value)}
                            defaultValue={tarsalTunnel}
                        >
                            {tarsalTunnelYesNo.map((yesno4, idx) => (
                            <option key={idx}>{yesno4}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Gout</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setGout(e.target.value)}
                            defaultValue={gout}
                        >
                            {goutYesNo.map((yesno5, idx) => (
                            <option key={idx}>{yesno5}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Plantar fasciitis</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPlantarFasciitis(e.target.value)}
                            defaultValue={plantarFasciitis}
                        >
                            {plantarFasciitisYesNo.map((yesno5, idx) => (
                            <option key={idx}>{yesno5}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Please List any Current Medical Conditions not Above 
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={conditionsNotListed} onChange={(e) => { setConditionsNotListed(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                    







                   

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion10;